<template>
  <div>
    <div v-if="imgUrl02" class="destoonAdPlace">
      <a :href="linkUrl" target="_blank">
        <img v-if="imgUrl02" :src="imgUrl02" alt="" />
      </a>
    </div>
    <div class="video-introduce">
      <span>视频介绍</span>
      <div v-html="PageMessage.courseDec"></div>
    </div>
    <div class="video-introduce mt26" v-if="PageMessage.courseSpot">
      <span>视频亮点</span>
      <div v-html="PageMessage.courseSpot"></div>
    </div>
  </div>
</template>

<script>
import { getAdver } from "network/common";

export default {
  name: "Introduce",
  props: { PageMessage: Object },
  data() {
    return {
      imgUrl02: "",
      linkUrl: "",
    };
  },
  methods: {
    // 广告位
    async getAds() {
      const res = await getAdver(2);
      if (res.data.length > 0) {
        this.imgUrl02 = res.data[0].url;
        this.linkUrl = res.data[0].linkUrl;
      }
    },
  },
  created() {
    this.getAds();
  },
};
</script>

<style scoped>
</style>