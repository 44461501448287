import { request } from './request'

export function getInfo(data) {
  return request({
    url: '/api/subjectstagecourse/info',
    method: 'post',
    data
  })
}

export function getSourceCodeList(sid) {
  return request({
    url: `/api/soundcode/list/${sid}`
  })
}

export function getArticleList(sid) {
  return request({
    url: `/api/article/list/${sid}`
  })
}


