<template>
  <div class="code_wrapper">
    <div v-for="item in sourceCodeList" class="code">
      <div class="description">
        <img
          v-if="item.type === 2"
          src="~@/assets/images/code@3x.png"
          alt=""
        />
        <img
          v-if="item.type === 1"
          src="~@/assets/images/tool@3x.png"
          alt=""
        />
        <div class="detail">
          <h1 v-html="item.codeName"></h1>
          <p v-html="item.codeDesc"></p>
        </div>
      </div>
      <div @click="downloadResource" class="download">立即下载</div>
    </div>
  </div>
</template>

<script>
import { getSourceCodeList } from "network/video";

export default {
  name: "SourceCodeList",
  props: {
  },
  data() {
    return {
      subjectId: parseInt(this.$route.params.subjectId),
      sourceCodeList: [],
    };
  },
  methods: {
    async getSourceCodeList(sid) {
      const res = await getSourceCodeList(sid);
      this.sourceCodeList = res.data;
    },
    downloadResource() {
      this.$emit("downloadResource");
    },
  },
  created() {
    this.getSourceCodeList(this.subjectId);
  },
};
</script>

<style scoped>
</style>