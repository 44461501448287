<template>
	<div class="video">
		<div class="main">
			<p class="brand">
				<a :href="'/' + addHrefSearch()" class="home">首页 > </a><a :href="'/' + addHrefSearch()" v-html="PageMessage.lineName" class="course"> </a> >
				<span v-html="PageMessage.courseName" class="course"></span>
			</p>

			<div class="video-contaier">
				<div class="video-contaier-inner">
					<video
						:src="videoUrl"
						id="my-video"
						class="video-js vjs-default-skin vjs-big-play-centered"
						controls
						preload="auto"
						width="694px"
						height="388px"
						:poster="PageMessage.url"
						v-if="videoUrl"
						style="object-fit: fill">
						<source id="videoSource" :src="videoUrl" type="video/mp4" />
						<p class="vjs-no-js">
							To view this video please enable JavaScript, and consider upgrading to a web browser that
							<a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
						</p>
					</video>
				</div>
				<div class="video-des">
					<div class="title">
						<p>
							<span>章节名称：</span>
							<em v-html="PageMessage.courseName" :title="PageMessage.courseName"></em>
						</p>
						<p>
							<span>章节归属：</span>
							<em v-html="PageMessage.lineName" :title="PageMessage.lineName"></em>
						</p>
						<div class="detail">
							<div :class="['levelColor' + PageMessage.level, 'advanced']">
								<span></span>
								<i v-html="formatLevel(PageMessage.level)"></i>
							</div>
							<div class="left">
								<p class="name">
									<img src="~@/assets/images/icons/number.png" alt="" />
									<span v-html="PageMessage.studyNum"></span> 人学习
								</p>
							</div>
						</div>
					</div>
					<div class="downloadAndShare">
						<div class="wrap">
							<h1>热门学科全套学习资料</h1>
							<div class="optContent">
								<ul>
									<li>
										<img src="~@/assets/images/icons/h_download.png" alt="" />
										<span>课程下载</span>
									</li>
									<li>
										<img src="~@/assets/images/icons/textbook.png" alt="" />
										<span>解析文档</span>
									</li>
								</ul>
								<ul>
									<li>
										<img src="~@/assets/images/icons/coding.png" alt="" />
										<span>工具源码</span>
									</li>
									<li>
										<img src="~@/assets/images/icons/chat.png" alt="" />
										<span>技术交流</span>
									</li>
								</ul>
							</div>
							<div class="tip">
								<p>高品质教程/工具/源码/文档</p>
								<p>海量资源一键打包</p>
							</div>
							<div class="optButton">
								<div @click="downloadResource">免费下载</div>
								<div>
									<a target="_blank" href="https://tb.53kf.com/code/client/10132404/14">在线咨询</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="tabs-des">
				<div class="tab-left">
					<el-tabs v-model="activeName">
						<el-tab-pane label="视频介绍" name="video">
							<introduce :PageMessage="PageMessage" />
						</el-tab-pane>

						<el-tab-pane label="课程目录" name="couseList">
							<course-list :outlineEntities="outlineEntities" @changeVideo="changeVideo" @downloadResource="downloadResource" />
						</el-tab-pane>

						<el-tab-pane label="工具源码" name="sourceCode">
							<source-code-list @downloadResource="downloadResource" />
						</el-tab-pane>

						<el-tab-pane label="技术文章" name="article">
							<article-list :subjectId="subjectId" />
						</el-tab-pane>
					</el-tabs>
				</div>
				<div class="tab-right">
					<!--             <div v-if='showImgSrc' class="showMsg">
                <a :href="linkUrl02" target="_blank">
                    <img :src="showImgSrc" v-if="showImgSrc" alt="">
                </a>
            </div> -->
					<div class="article-box">
						<div class="title">
							<h1>技术文章</h1>
							<a :href="'/articleList' + addHrefSearch()" style="color: #018dd6; font-size: 14px; margin-top: 2px" target="_blank">More ></a>
						</div>
						<div class="article-list">
							<a :href="`/article/${item.id}` + addHrefSearch()" class="article" target="_blank" v-for="item in allArticleList.slice(0, 6)">
								■ &nbsp; {{ item.articleTitle }}</a
							>
						</div>
					</div>

					<div class="teacher">
						<h1 class="title">主讲老师</h1>
						<div class="des">
							<img :src="teacherEntityObj.photo" alt="" />
							<div class="name">
								<h1 v-html="teacherEntityObj.teacherName"></h1>
								<p v-html="teacherEntityObj.title"></p>
							</div>
						</div>
						<div class="experience">
							<div>名师介绍</div>
							<span v-html="teacherEntityObj.teacherDesc"></span>
						</div>
					</div>

					<hot-labels />
				</div>
			</div>
		</div>

		<el-dialog :lock-scroll="false" custom-class="joinus" :visible.sync="dialogVisible" width="835px">
			<div class="header">
				<div class="steps">
					<span>1</span>
					<div class="des">
						<p>第一步</p>
						<p>扫码</p>
					</div>
				</div>
				<div class="steps line"></div>
				<div class="steps">
					<span>2</span>
					<div class="des">
						<p>第二步</p>
						<p>选择</p>
					</div>
				</div>
				<div class="steps line"></div>
				<div class="steps">
					<span>3</span>
					<div class="des">
						<p>第3步</p>
						<p>领取</p>
					</div>
				</div>
			</div>
			<div class="direction">
				<div class="code">
					<div id="qrcode"><img src="https://www.embedtrain.org/images/qrcode/qrcode.png" /></div>
				</div>
				<div class="item">
					<div class="content">
						<p>扫码进入</p>
						<p v-html="officialName"></p>
					</div>
				</div>
				<img src="~@/assets/images/icons/arrow.png" alt="" />
				<div class="item">
					<div class="content">
						<p></p>
						<p>选择所需学科</p>
						<!-- <p v-html='keyName'></p> -->
					</div>
				</div>
				<img src="~@/assets/images/icons/arrow.png" alt="" />
				<div class="item">
					<div class="content">
						<p></p>
						<p>获取全套课程资料</p>
						<!-- <p>下载地址及密码</p> -->
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
// import "assets/css/video.less";

import Introduce from "./childComps/Introduce";
import CourseList from "./childComps/CourseList.vue";
import SourceCodeList from "./childComps/SourceCodeList";
import ArticleList from "./childComps/ArticleList";
import HotLabels from "common/HotLabels";

import { getInfo } from "network/video";
// import { getAdver } from "network/common";
import { mapState } from "vuex";

export default {
	name: "Video",
	components: {
		Introduce,
		SourceCodeList,
		ArticleList,
		CourseList,
		HotLabels,
	},
	computed: {
		...mapState({
			allArticleList: (store) => store.ar.allArticleList,
		}),
	},
	data() {
		return {
			subjectId: parseInt(this.$route.params.subjectId),
			PageMessage: {},
			keyName: "",
			teacherEntityObj: {},
			outlineEntities: [],
			officialAccountUrl: "",
			officialName: "",
			videoUrl: "",
			dialogVisible: false,
			// 右侧广告位
			showImgSrc: "",
			linkUrl02: "",

			activeName: "video",
		};
	},
	methods: {
		async initInfo(i) {
			const res = await getInfo(this.$route.params);
			this.PageMessage = res.data;
			this.keyName = res.data.subjectName;
			this.teacherEntityObj = res.data.teacherEntity;
			this.outlineEntities = res.data.outlineEntities;
			this.officialAccountUrl = res.data.officialAccountUrl;
			this.officialName = res.data.officialName;

			this.videoUrl = this.outlineEntities[i].url;
			document.title = this.PageMessage.lineName + " | " + this.PageMessage.courseName; // 更改标题
		},
		/*     async getAdver() {
      const res = await getAdver(1);
      if (res.data.length > 0) {
        this.showImgSrc = res.data[0].url;
        this.linkUrl02 = res.data[0].linkUrl;
      }
    }, */
		downloadResource() {
			this.dialogVisible = true;
			this.$nextTick(() => {
				// this.generateQrcode();
			});
		},
		changeVideo(idx) {
			this.videoUrl = this.outlineEntities[idx].url;
		},
		/*     handleClick(tab, event) {
      console.log(tab.index);
      let subjectId = getParamValue("subjectId");
      if (tab.index === "2") {
        this.getSourceCodeList(subjectId);
      } else if (tab.index === "3") {
        this.getArticleList(subjectId);
      } else if (tab.index === "0" || tab.index === "1") {
        this.initPage("", "tab");
      }
    }, */
	},
	mounted() {
		document.querySelector(".header").scrollIntoView();
	},
	created() {
		this.initInfo(0);
		// this.getAdver();

		if (!this.allArticleList.length) this.$store.dispatch("INIT_ARTICLES");
	},
};
</script>

<style scoped>
@import url("~@/assets/css/video.css");

.tab-right .article-box {
	margin-bottom: 20px;
	box-sizing: border-box;
	padding: 20px 20px 25px 20px;
	border-radius: 10px;
	background: #ffffff;
}
.tab-right .article-box .title {
	margin-bottom: 5px;
	border-bottom: 1px solid #cccccc;
	position: relative;
	font-size: 16px;
	padding-bottom: 11px;
	display: flex;
	justify-content: space-between;
}
.tab-right .article-box .title::before {
	content: "";
	border-style: solid;
	border-width: 0 7px 7px;
	border-color: transparent transparent #018dd6;
	position: absolute;
	top: 26px;
	left: 25px;
	justify-content: space-between;
}
.tab-right .article-box .title::after {
	content: "";
	height: 2px;
	width: 66px;
	display: inline-block;
	position: absolute;
	background: #018dd6;
	top: 32px;
	left: 0;
}
.tab-right .article-box .title h1 {
	font-size: 16px;
}
.tab-right .article-box .article-list a {
	display: block;
	margin-top: 10px;
	font-size: 14px;
	line-height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #666666;
}
.tab-right .article-box .article-list a:hover {
	color: #028ed7;
}
</style>
