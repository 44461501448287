<template>
  <div class="article-list__wrapper">
    <div
      v-for="article in articleList"
      @click="jumpToArticleList(article)"
      class="article-list"
    >
      <h1 v-html="article.articleTitle"></h1>
      <p v-html="spliceMesage(article.content)"></p>
      <div class="message">
        <div class="des">
          <span
            :title="article.subjectName"
            v-html="article.subjectName"
          ></span>
          <em>关键词 ：<i v-html="article.keyWordName"></i> </em>
          <i class="eyes"
            ><img src="~@/assets/images/icons/eye@3x.png" alt="" />
            <div style="display: inline-block" v-html="article.lookNum"></div>
          </i>
        </div>
        <div v-html="article.createTime.slice(0, 10)" class="timer"></div>
      </div>
    </div>
    <el-empty description="暂无文章" v-if='!articleList.length'></el-empty>
  </div>
</template>

<script>
// import { removeHTMLTag, addHrefSearch } from "assets/js/util";
import { getArticleList } from "network/video";

export default {
  name: "ArticleList",
  props: {
    subjectId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      articleList: [],
    };
  },
  methods: {
    async getArticleList(sid) {
      const res = await getArticleList(sid);
      this.articleList = res.data;
    },
    jumpToArticleList(item) {
      this.$router.push({
        path: "/article/" + item.id,
        query: this.$route.query,
      });
    },
    spliceMesage(str) {
      return this.removeHTMLTag(str.slice(0, 400));
    },
  },
  created() {
    this.getArticleList(this.subjectId);
  },
};
</script>

<style scoped>
</style>