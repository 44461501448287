<template>
  <div class="course-list">
    <div
      v-for="(list, index) in outlineEntities"
      @click="changeVideo(index, list)"
      :class="{ novideo: list.url == '', hasVideo: list.url }"
      class="list"
    >
      <div :title="list.outlineName" class="left">
        <div class="arrow-left"></div>
        <p v-html="list.outlineName"></p>
      </div>
      <div class="videoplayer"></div>
    </div>

  </div>
</template>

<script>

export default {
  name: "CourseList",
  props: {
    outlineEntities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      subjectId: parseInt(this.$route.params.subjectId),
    };
  },
  methods: {
    changeVideo(idx, item) {
      if(!item.url) return this.$emit('downloadResource')
      this.$emit('changeVideo',idx)
    },
  },
};
</script>

<style scoped>
</style>